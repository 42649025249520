import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
	REFRESH_DATA_USER_PROFILE
} from '../../constants/UserProfile';
import {
	getUserProfile,
	setEmptyUserProfile,
	setTryGetUserProfile,
	showMessageUserProfile,
} from "../actions/UserProfile";

import UserProfileService from 'services/UserProfileService'


export function* fetchUserProfile() {
  yield takeEvery(REFRESH_DATA_USER_PROFILE, function* ({payload}) {
	  const token = payload;
		try {
			//console.log('Saga token:', token);
			yield put(setEmptyUserProfile());
			yield put(setTryGetUserProfile(token));
			const dataUserProfile = yield call([UserProfileService, UserProfileService.getProfile]);
			//console.log('dataUserProfile:', dataUserProfile);
			const {User, UserProfile} = dataUserProfile;
			yield put(getUserProfile(User, UserProfile, token));
		} catch (err) {
			//console.log('Saga err:', err.toString());
			yield put(showMessageUserProfile('Error determining user profile data', err.toString()));
		}
	});
}


export default function* rootSaga() {
  yield all([
		fork(fetchUserProfile)
  ]);
}
