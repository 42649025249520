import {
  SET_VISIBLE,
  SET_ON_CHANGE,
  SET_SEARCH_QUERY
} from '../../constants/HeaderSearch';

const initState = {
  isVisible: false,
  onChange: null,
  searchQuery: ''
}

const headerSearch = (state = initState, action) => {

    switch (action.type) {
      case SET_VISIBLE:
          return {
            ...state,
            isVisible: action.payload,
          }

      case SET_ON_CHANGE:
        return {
          ...state,
          onChange: action.payload,
        }

      case SET_SEARCH_QUERY:
        return {
          ...state,
          searchQuery: action.payload,
        }

        default:
            return state;
    }
}


export default headerSearch