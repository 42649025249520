import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux'
import {Row, Col, Divider, message, Modal, Form, Input, Slider, InputNumber} from 'antd';
import CommonService from "services/CommonService";
import {showErrors} from "../../../services/utils";
import './questionnaire-modal.css';
import {formatterNumber} from "components/shared-custom-components/util/func";
import isEmpty from 'lodash/isEmpty';
const { TextArea } = Input;


const nameItemStorage = 'lastShowQuestionnaireTime';

const QuestionnaireModal = (props) => {

  const {onShow} = props;

  const [form] = Form.useForm();

  const [isMounted, setIsMounted] = useState(true);
  const [visibleModal, setVisibleModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [cancelButtonDisabled, setCancelButtonDisabled] = useState(false);
  const [currentQuestionnaire, setCurrentQuestionnaire] = useState({});
  const [questionnaires, setQuestionnaires] = useState([]);

  useEffect(() => {
    let cancelled = false;

    let requests = [
      CommonService.getQuestionnaireResponses()
    ];

    Promise.all(requests)
      .then((responses) => {

        if (cancelled) {
          return;
        }

        const dataQuestionnaires = responses[0].data;

        if (!dataQuestionnaires?.length) {
          return;
        }
        const nextQuestionnaire = dataQuestionnaires.shift();
        // console.log('000 nextQuestionnaire:', nextQuestionnaire)

        setCurrentQuestionnaire(nextQuestionnaire);
        setQuestionnaires(dataQuestionnaires);

        setVisibleModal(true);

      })
      .catch((error) => {
        console.log('error:', error);
        const {errorMsg} = showErrors(error);
        message.error(errorMsg);
      })
      .finally(() => {});

    return () => {
      cancelled = true;
      setIsMounted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveLastShowTime = () => {
    const currentTime = new Date().getTime();
    localStorage.setItem(nameItemStorage, `${currentTime}`);
  }

  const onCancel = () => {
    saveLastShowTime();
    setVisibleModal(false);
    onShow();
  };

  const onConfirm = () => {

    form
      .validateFields()
      .then((values) => {
        setCancelButtonDisabled(true);
        setConfirmLoading(true);

        const data = {};
        const ratings = [];
        let questionnaireId;

        for (let key in values) {
          if (key === 'comment') {
            data[key] = values[key] || '';
          }

          if (key === 'questionnaire_id') {
            questionnaireId = values[key];
          }

          if (key.startsWith('rating__')) {

            const regex = /__(\d+)/;
            const matches = key.match(regex);

            const questionId = parseInt(matches[1], 10);

            ratings.push({
              question: questionId,
              rating: values[key],
            });

          }
        }

        data.ratings = ratings;


        CommonService.createQuestionnaireResponse(questionnaireId, data)
          .then((_response) => {

            // console.log('questionnaires', questionnaires)
            const newQuestionnaires = [...questionnaires];
            const nextQuestionnaire = newQuestionnaires.shift();

            // console.log('nextQuestionnaire:', nextQuestionnaire)
            if (isEmpty(nextQuestionnaire)) {
              onShow();
              return;
            }

            setCurrentQuestionnaire(nextQuestionnaire);
            setQuestionnaires(newQuestionnaires);

            form.resetFields();

          })
          .catch((error) => {
            const {errorMsg} = showErrors(error);
            message.error(errorMsg);
          })
          .finally(() => {

            if (!isMounted) {
              return;
            }

            setConfirmLoading(false);
            setCancelButtonDisabled(false);
          });


      })
      .catch((_info) => {
        console.log('Validate Failed:', _info);
      })
      .finally(() => {
        setCancelButtonDisabled(false);
      });
  };

  const updateRatingQuestionCurrentQuestionnaire = (questionId) => (rating) => {


    form.setFieldsValue({ [`rating__${questionId}`]: rating });

    setCurrentQuestionnaire((prevCurrentQuestionnaire) => {

      return {
        ...prevCurrentQuestionnaire,
        questions: prevCurrentQuestionnaire.questions.map((question) => {

          if (question.question_id !== questionId) {
            return { ...question };
          }

          return {
            ...question,
            rating,
          }
        })
      }
    });
  }

  const renderContent = () => {

    if (isEmpty(currentQuestionnaire)) {
      return null;
    }

    const {
      id: questionnaireId,
      description,
      allow_comment: allowComment,
      questions,
    } = currentQuestionnaire;

    const questionList = [];

    for (const question of questions) {
      const {
        question_id: questionId,
        number,
        text,
        rating = 0
      } = question;

      const itemQuestion = (
        <Row
          key={`question_${questionId}`}
          gutter='16'
        >
          <Col xs={24} sm={24} md={10}>
            {number}. {text}
          </Col>
          <Col xs={24} sm={24} md={14}>
            <Form.Item
              key={`rating_${questionId}`}
              name={`rating__${questionId}`}
              initialValue={rating}
              className={'rating'}
              rules={[
                {
                  type: 'number',
                  min: 1,
                  max: 10,
                  message: 'Rating must be between 1 and 10',
                  transform: (value) => {
                    try {
                      return parseInt(value, 10) || 0;
                    } catch (error) {
                      return 0;
                    }
                  },
                },
              ]}
            >
              <Row>
                <Col xs={24} sm={24} md={20} style={{paddingLeft: 8, paddingRight: 8}}>
                  <Slider
                    min={1}
                    max={10}
                    onChange={updateRatingQuestionCurrentQuestionnaire(questionId)}
                    value={typeof rating === 'number' ? rating : 0}
                  />
                </Col>
                <Col xs={24} sm={24} md={4} style={{paddingLeft: 8, paddingRight: 8}}>
                  <InputNumber
                    min={1}
                    max={10}
                    value={+rating}
                    style={{width: '100%'}}
                    onChange={updateRatingQuestionCurrentQuestionnaire(questionId)}
                    formatter={formatterNumber}
                  />
                </Col>

              </Row>
            </Form.Item>
          </Col>

          {/*<Col xs={24} sm={24} md={12}>*/}
          {/*  <Slider*/}
          {/*    min={0}*/}
          {/*    max={10}*/}
          {/*    onChange={updateRatingQuestionCurrentQuestionnaire(questionId)}*/}
          {/*    value={typeof rating === 'number' ? rating : 0}*/}
          {/*  />*/}
          {/*</Col>*/}
          {/*<Col xs={24} sm={24} md={2}>*/}
          {/*  <Form.Item*/}
          {/*    key={`rating_${questionId}`}*/}
          {/*    name={`rating__${questionId}`}*/}
          {/*    initialValue={rating}*/}
          {/*    className={'rating'}*/}
          {/*    rules={[*/}
          {/*      {*/}
          {/*        type: 'number',*/}
          {/*        min: 1,*/}
          {/*        max: 10,*/}
          {/*        message: 'Rating must be between 1 and 10',*/}
          {/*      },*/}
          {/*    ]}*/}
          {/*  >*/}
          {/*    <InputNumber*/}
          {/*      min={1}*/}
          {/*      max={10}*/}
          {/*      value={rating}*/}
          {/*      style={{width: '100%'}}*/}
          {/*      onChange={updateRatingQuestionCurrentQuestionnaire(questionId)}*/}
          {/*      formatter={formatterNumber}*/}
          {/*    />*/}
          {/*  </Form.Item>*/}
          {/*</Col>*/}
        </Row>
      );

      questionList.push(itemQuestion)
    }

    return (
      <>
        <div className='questionnaire-form-wrapper'>
          <Form form={form} name='questionnaire_form' autoComplete='off'>
            <Row gutter='16'>

              <Col xs={24} sm={24} md={24}>

                <Form.Item
                  name={'questionnaire_id'}
                  label={false}
                  initialValue={questionnaireId}
                  hidden
                >
                  <Input />
                </Form.Item>

                <div className={'ql-editor'} dangerouslySetInnerHTML={{ __html: description }} />

              </Col>

            </Row>

            <Divider />

            {questionList}

            {allowComment && (
              <Row gutter='16'>
                <Col xs={24} sm={24} md={24}>

                  <Form.Item
                    key={'comment'}
                    name={'comment'}
                    label={false}
                    labelCol={{span: 24,}}
                    wrapperCol={{}}
                  >
                    <TextArea
                      rows={4}
                      style={{width: '100%'}}
                      placeholder={'Add Comment...'}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}

          </Form>
        </div>
      </>
    )

  }

  return (
    <Modal
      className='questionnaire-modal'
      title={currentQuestionnaire?.caption || 'Survey'}
      visible={visibleModal}
      onOk={onConfirm}
      okButtonProps={{
        disabled: false,
      }}
      onCancel={onCancel}
      width={800}
      confirmLoading={confirmLoading}
      cancelButtonProps={{
        disabled: cancelButtonDisabled
      }}
    >
      {renderContent()}
    </Modal>
  )
}

const mapStateToProps = ({ userProfile }) => {
  return { userProfile }
};

export default connect(mapStateToProps)(QuestionnaireModal);


const needShowQuestionnaireModal = () => {

  const lastShowTime = +localStorage.getItem(nameItemStorage);

  if (!lastShowTime) {
    return true;
  }

  const currentTime = new Date().getTime();

  const twoHoursInMilliseconds = 2 * 60 * 60 * 1000;
  return currentTime - lastShowTime >= twoHoursInMilliseconds;
}

export { needShowQuestionnaireModal }