import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/shipping`} component={lazy(() => import(`./shipping`))} />
        <Route path={`${APP_PREFIX_PATH}/shipping-history`} component={lazy(() => import(`./shipping-history`))} />
        <Route path={`${APP_PREFIX_PATH}/payments`} component={lazy(() => import(`./payment`))} />
        <Route path={`${APP_PREFIX_PATH}/users`} component={lazy(() => import(`./users`))} />
        <Route path={`${APP_PREFIX_PATH}/tools`} component={lazy(() => import(`./tools`))} />
        <Route path={`${APP_PREFIX_PATH}/help-desk`} component={lazy(() => import(`./help-desk`))} />
        <Route path={`${APP_PREFIX_PATH}/carfax`} component={lazy(() => import(`./carfax`))} />
        <Route path={`${APP_PREFIX_PATH}/ezparts`} component={lazy(() => import(`./ezparts`))} />
        <Route path={`${APP_PREFIX_PATH}/customers`} component={lazy(() => import(`./customers`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/shipping`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);