
export function showErrors(error) {
    // const { response: { data: responseData = {} } } = error;
    let responseData = {};

    if (error && error.response && error.response.data) {
      responseData = error.response.data;
    }

    let errorMsg = [];
    let errorFields = [];

    if (typeof responseData?.error === 'object') {
        for (let i in responseData.error) {
            if (responseData.error.hasOwnProperty(i)) {
                errorMsg.push(<p key={i}>{i}: {responseData.error[i]}</p>);
                errorFields.push({
                    name: i,
                    errors: responseData.error[i],
                });
            }
        }
    } else if (typeof responseData?.error === 'string') {
        errorMsg.push(<p key={'errorString'}>{responseData?.error}</p>);
    } else {
        errorMsg.push(<p key={'errorOther'}>Error</p>);
    }

    return { errorMsg, errorFields }
}

export default function generateBoundary() {
    let boundary = '--------------------------';
    for (let i = 0; i < 24; i++) {
        boundary += Math.floor(Math.random() * 10).toString(16);
    }

    return boundary;
}