
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import _ from 'lodash';

const paymentsNavTree = [{
  key: 'payments',
  path: `${APP_PREFIX_PATH}/payments`,
  title: 'sidenav.payments',
  fromBackend: '',
  navItem: true,
  icon: false,
  breadcrumb: false,
  submenu: [
    {
      key: 'payments-paymentsList',
      path: `${APP_PREFIX_PATH}/payments/payments-list`,
      title: 'sidenav.payments.paymentsList',
      fromBackend: 'show_payments',
      navItem: false,
      icon: false,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'payments-paymentAccount',
      path: `${APP_PREFIX_PATH}/payments/payment-account`,
      title: 'sidenav.payments.paymentAccount',
      fromBackend: 'show_payments',
      navItem: false,
      icon: false,
      breadcrumb: false,
      submenu: []
    },
  ]
}];

const shippingNavTree = [{
  key: 'shipping',
  path: `${APP_PREFIX_PATH}/shipping`,
  title: 'sidenav.shipping',
  fromBackend: '',
  navItem: true,
  icon: false,
  breadcrumb: false,
  submenu: [
    {
      key: 'shippingList',
      path: `${APP_PREFIX_PATH}/shipping/shipping-list`,
      title: 'sidenav.shipping.shippingList',
      fromBackend: '',
      navItem: false,
      icon: false,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'shippingHistoryList',
      path: `${APP_PREFIX_PATH}/shipping-history/shipping-history-list`,
      title: 'sidenav.shipping.shippingHistoryList',
      fromBackend: '',
      navItem: false,
      icon: false,
      breadcrumb: false,
      submenu: []
    }
  ]
}];

const carfaxNavTree = [{
  key: 'carfax',
  path: `${APP_PREFIX_PATH}/carfax`,
  title: 'sidenav.carfax',
  fromBackend: 'show_carfax',
  navItem: false,
  icon: false,
  breadcrumb: false,
  submenu: []
}];

const ezpartsNavTree = [{
  key: 'ezparts',
  path: `${APP_PREFIX_PATH}/ezparts`,
  title: 'sidenav.ezparts',
  fromBackend: 'show_carfax',
  navItem: false,
  icon: false,
  breadcrumb: false,
  submenu: []
}];


const toolsNavTree = [{
  key: 'tools',
  path: `${APP_PREFIX_PATH}/tools`,
  title: 'sidenav.tools',
  fromBackend: '',
  navItem: true,
  icon: false,
  breadcrumb: false,
  submenu: [
    {
      key: 'calculator',
      path: `${APP_PREFIX_PATH}/tools/calculator`,
      title: 'sidenav.tools.calculator',
      fromBackend: 'show_calculator',
      navItem: false,
      icon: false,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'title-check',
      path: `${APP_PREFIX_PATH}/tools/title-check`,
      title: 'sidenav.tools.titleCheck',
      fromBackend: '',
      navItem: false,
      icon: false,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'instructions-templates',
      path: `${APP_PREFIX_PATH}/tools/instructions-templates`,
      title: 'sidenav.tools.instructionsTemplates',
      fromBackend: '',
      navItem: false,
      icon: false,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'export-delivery-to-port-price',
      path: `${APP_PREFIX_PATH}/tools/export-delivery-to-port-price`,
      title: 'sidenav.tools.exportDeliveryToPortPrice',
      fromBackend: 'export_delivery_to_port_price',
      navItem: false,
      icon: false,
      breadcrumb: false,
      submenu: []
    }
  ]
}];

const helpDeskNavTree = [
  {
    key: 'helpDesk',
    path: `${APP_PREFIX_PATH}/help-desk/tickets`,
    title: 'sidenav.helpDesk',
    fromBackend: 'show_helpdesk',
    navItem: false,
    icon: false,
    breadcrumb: false,
    submenu: []
  },
];

const customersNavTree = [{
  key: 'customers',
  path: `${APP_PREFIX_PATH}/customers`,
  title: 'sidenav.customers',
  fromBackend: '',
  navItem: false,
  icon: false,
  breadcrumb: false,
  submenu: []
}]


const navigationConfig = [
  ...shippingNavTree,
  ...paymentsNavTree,
  ...carfaxNavTree,
  // ...ezpartsNavTree,
  ...toolsNavTree,
  ...customersNavTree,
  ...helpDeskNavTree,
];

export default navigationConfig;


export const getNavigationConfig = (navigationConfig, navPermissions) => {

  const newNavigationConfig = _.cloneDeep(navigationConfig);

  const markNavigationConfig = (searchBackendName, searchParentBackendName='' ) => {

    const markingProcess = (navigationConfig, parentItemNavigation='') => {
      // console.log('markNavigationConfig->',
      //   'searchBackendName:', searchBackendName,
      //   'searchParentBackendName:',searchParentBackendName,
      //   'parentItemNavigation:', parentItemNavigation,
      //   'newNavigationConfig:', navigationConfig)

      navigationConfig.forEach((item) => {

        if (item?.fromBackend === '') {
          item.isLeave = true;
        }
        if (item?.navItem === true) {
          item.isLeave = true;
        }
        if (item?.fromBackend === searchBackendName) {
          item.isLeave = true;
        } else {
          // console.log(item?.fromBackend,'|', searchBackendName)
        }
        // if (item?.key === 'helpDesk' || item?.key === 'helpDeskAudit' || item?.key === 'assignee-category') {
        //   item.isLeave = true;
        // }
        // if (item?.key === 'export-carfax-report') {
        //   item.isLeave = true;
        // }

        markingProcess(item.submenu, item?.fromBackend ?? '');
      })
    }

    markingProcess(newNavigationConfig);

  }


  const iteratePermissions = (navPermissions, parentItemPermission='') => {

    // console.log('parentItemPermission:', parentItemPermission, 'navPermissions:',navPermissions)

    for (let itemPermission in navPermissions) {

      if( navPermissions.hasOwnProperty(itemPermission)) {

        let searchName = itemPermission.trim();
        if (!navPermissions[itemPermission]) {
          searchName = `${searchName}-off`;
        }

        markNavigationConfig(searchName, parentItemPermission);

         // console.log('parentItemPermission:', parentItemPermission, 'itemPermission:',itemPermission)
        if( typeof navPermissions[itemPermission] === 'object' ) {
          iteratePermissions(navPermissions[itemPermission], itemPermission.trim());
        }

      }

    }

  }

  const filterAllowedNavigationConfig = (newNavigationConfig) => {
    const result = [];
    let subMenu;
    let newItem;
    for(let i = 0; i < newNavigationConfig.length; i++) {
      if (_.isObject(newNavigationConfig[i]) && newNavigationConfig[i]?.isLeave === true ) {
        subMenu = [];

        if (newNavigationConfig[i]?.submenu !== undefined &&
            _.isArray(newNavigationConfig[i].submenu) && !_.isEmpty(newNavigationConfig[i].submenu)) {
          subMenu = filterAllowedNavigationConfig(newNavigationConfig[i].submenu);
        }
        if (newNavigationConfig[i]?.navItem === true &&
          _.isEmpty(subMenu)) {
          continue;
        }

        newItem = {...newNavigationConfig[i]};
        if (!_.isEmpty(subMenu)) {
          newItem.submenu = subMenu;
        }

        // console.log('newNavigationConfig[i]:',newNavigationConfig[i])
        result.push(newItem);
      }
    }
    return result;
  }

  iteratePermissions(navPermissions)

  // console.log('newNavigationConfig:', newNavigationConfig);

  return filterAllowedNavigationConfig(newNavigationConfig);
}


