import fetch from 'auth/FetchInterceptor'

const CommonService = {
	_url: '/rest/v1/dealer',

	async _getResource(url) {
		const resp = await fetch({
			url: `${this._url}${url}`,
			method: 'get'
		});
		return resp.data;
	},
	async _setResource(url, {
		method='post',
		data,
		headers = {}}
   ) {
		const resp = await fetch({
			url: `${this._url}${url}`,
			method: method,
			data,
			headers
		});
		return resp.data;
	},
	async _optResource(url) {
		const resp = await fetch({
			url: `${this._url}${url}`,
			method: 'options'
		});
		return resp.data;
	},
	async _downloadZip(url) {
    return await fetch({
      url: `${this._url}${url}`,
      method: 'get',
      responseType: 'blob',
      headers: {
        'Content-Type': `application/zip`,
      },

    });
	},


  async getAttentionList() {

    const url = `/attention?page_size=10000`;

    const resp = await this._getResource(url);
    return {
      data: resp?.results || []
    };
  },

  async getQuestionnaireResponses() {

    const url = `/questionnaire-responses`;

    const resp = await this._getResource(url);
    return {
      data: resp?.results || []
    };
  },

  async createQuestionnaireResponse(id, data) {

    const url = `/questionnaire-responses/${id}/create-questionnaire-response`;

    const resp = await this._setResource(url,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'post',
        data: JSON.stringify(data),
      });

    return {
      data: resp
    };

  },


  async getHappyBirthdayGreeting() {

    const url = `/happy-birthday-greeting/get-greeting`;

    const resp = await this._getResource(url);
    return {
      data: resp?.results
    };
  },


  async doneGreeting() {

    const url = `/happy-birthday-greeting/done-greeting`;

    const resp = await this._setResource(url,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'post',
        data: JSON.stringify({}),
      });

    return {
      data: resp
    };

  },

};

export default CommonService;