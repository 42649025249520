import React, { useRef, useEffect } from 'react';
import { connect } from "react-redux";
import { CloseOutlined } from '@ant-design/icons';
import utils from 'utils';
import SearchInput from './SearchInput';
import './header-search.css';

const HeaderSearch = (props) => {
  const {
    active,
    close,
    headerNavColor,
  } = props;
  const mode = utils.getColorContrast(headerNavColor)

  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, [active]);


  return (
    <div className={`nav-search ${active ? 'nav-search-active' : ''} ${mode}`} style={{backgroundColor: headerNavColor}}>
      <div className="d-flex align-items-center w-100">
        <SearchInput ref_={inputRef} />
      </div>
      <div className="nav-close" onClick={close}>
        <CloseOutlined />
      </div>
    </div>
  );
}

const mapStateToProps = ({ theme }) => {
  const { headerNavColor } =  theme;
  return { headerNavColor }
};

export default connect(mapStateToProps)(HeaderSearch);
