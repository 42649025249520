import { combineReducers } from 'redux';
import Auth from './Auth';
import UserProfile from './UserProfile';
import HelpDesk from './HelpDesk';
import Theme from './Theme';
import HeaderSearch from "./HeaderSearch";

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  userProfile: UserProfile,
  helpDesk: HelpDesk,
  headerSearch: HeaderSearch,
});

export default reducers;