import React from "react";
import _ from "lodash";
import NumberFormat from "react-number-format";


export const renderAsNumber = (value, {prefix = '', suffix = '', precision = 2, zero_allowed = false} = {}) => {
    const _value = _.toNumber(value);
    if (!_.isNumber(_value)) return value;
    if (!_value && !zero_allowed) return null;

    let k = Math.pow(10, precision);;
    return (
        <NumberFormat
            displayType={'text'}
            value={(Math.round(_value * k) / k).toFixed(precision)}
            prefix={prefix}
            suffix={suffix}
            thousandSeparator={' '}
        />
    );
};


export const formatterNumber = (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const formatterNumberFunc = ({prefix = '', suffix = ''} = {}) => {
    return (value) => {
        return `${prefix}${value}${suffix}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
}
