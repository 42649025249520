import React from 'react'
import {Link} from "react-router-dom";
import {HomeBi, CollectionBi, Folder2OpenBi, ChatDotsBi} from "../../assets/svg/icon";
import {Badge} from 'antd';
import CustomIcon from "../util-components/CustomIcon";
import {connect} from "react-redux";

function Footer(props) {
  const {routeInfo, countUnreadMessages} = props;
  const getClassName = (key) => {
    return routeInfo?.key === key ? 'menu-item-selected' : '';
  }


	return (
		<footer className="footer">
			{/*<span>Copyright  &copy;  {`${new Date().getFullYear()}`} <span className="font-weight-semibold">{`${APP_NAME}`}</span> All rights reserved.</span>*/}

      <Link to={'/app/shipping/shipping-list'} className={getClassName('shippingList')} >
        <CustomIcon svg={HomeBi}/><span style={{marginTop: -5}}>Shipping</span>
      </Link>
      <Link to={'/app/payments/payments-list'} className={getClassName('payments-paymentsList')} >
        <CustomIcon svg={CollectionBi}/><span>Payments</span>
      </Link>
      <Link to={'/app/carfax'} className={getClassName('carfax')} >
        <CustomIcon svg={Folder2OpenBi}/><span>Carfax</span>
      </Link>
      <Link to={'/app/help-desk/tickets'} className={getClassName('helpDesk')} >
        <Badge count={countUnreadMessages}>
          <CustomIcon svg={ChatDotsBi}/><span>HelpDesk</span>
        </Badge>
      </Link>
		</footer>
	)
}

const mapStateToProps = ({ helpDesk }) => {
  const { countUnreadMessages } =  helpDesk;
  return { countUnreadMessages }
};

export default connect(mapStateToProps)(Footer);