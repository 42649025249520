import React, {useEffect, useState} from "react";
import {Route, Switch, Redirect, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import {IntlProvider} from "react-intl";
import {ConfigProvider} from 'antd';
import {APP_PREFIX_PATH, AUTH_PREFIX_PATH} from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass';
import {refreshDataUserProfile} from "../redux/actions/UserProfile";
import UserProfileService from "../services/UserProfileService";
import Loading from 'components/shared-components/Loading';
import AcceptRules from "../components/shared-components/AcceptRules";

function RouteInterceptor({children, isAuthenticated, ...rest}) {
  return (
    <Route
      {...rest}
      render={({location}) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: {from: location}
            }}
          />
        )
      }
    />
  );
}

export const Views = (props) => {
  const {
    locale, token, location, direction,
    refreshDataUserProfile,
  } = props;
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);

  const [rulesAreAccepted, setRulesAreAccepted] = useState(null);

  useEffect(() => {
    let cancelled = false;

    if (token) {
      UserProfileService.getCustomerAdditionalSettings()
        .then((res) => {
          if (!cancelled) {
            setRulesAreAccepted(res?.rules_are_accepted);
            // setRulesAreAccepted(false);
          }
        });

      refreshDataUserProfile(token);
    }

    return () => cancelled = true;
  }, [
    token,
    refreshDataUserProfile,
  ]);

  // console.log('location:', location)

  if ( token && location?.pathname?.startsWith(`${APP_PREFIX_PATH}/`)) {

    if (rulesAreAccepted === null && location?.pathname !== '/') {
      return (
        <Loading cover="page" />
      );
    }

    if (rulesAreAccepted !== true) {
      return (
        <AcceptRules
          onConfirm={(acceptedRules) => setRulesAreAccepted(acceptedRules)}
        />
      );
    }

  }

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path="/">
            <Redirect to={APP_PREFIX_PATH}/>
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction}/>
          </Route>
          <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
            <AppLayout direction={direction} location={location}/>
          </RouteInterceptor>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  )
}

const mapStateToProps = ({theme, auth, userProfile}) => {
  const {locale, direction} = theme;
  const {token} = auth;
  return {locale, token, direction, userProfile}
};
const actionCreators = {
  refreshDataUserProfile
};

export default withRouter(connect(mapStateToProps, actionCreators)(Views));