import fetch from 'auth/FetchInterceptor'

const UploadsService = {
	_url: '',

	async _getResource(url) {
		const resp = await fetch({
			url: `${this._url}${url}`,
			method: 'get'
		});
		return resp.data;
	},
	async _download(url) {
		const resp = await fetch({
			url: `${this._url}${url}`,
			method: 'get',
			responseType: 'blob',
			/*
			headers: {
				'Content-Type': `application/zip`,
			},
		 	*/

		});
		return resp;
	},
};

export default UploadsService;