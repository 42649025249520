import React, {useEffect, useState} from 'react';
import {Button, message, Modal} from 'antd';
import { connect } from 'react-redux'


import './attention-modal.css';
import CommonService from "services/CommonService";
import UserProfileService from "../../../services/UserProfileService";
import {showErrors} from "../../../services/utils";




const AttentionModal = (props) => {
  const {onShow} = props;
  const [readMore, setReadMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const [visibleModal, setVisibleModal] = useState(true);
  const [attentionListContent, setAttentionListContent] = useState(null);
  const [attentionTopContent, setAttentionTopContent] = useState(null);



  useEffect(() => {
    let cancelled = false;
    setLoading(true);

    let requests = [
      UserProfileService.getProfile(),
      CommonService.getAttentionList()
    ];
    Promise.all(requests)
      .then((responses) => {
        if (!cancelled) {
          const userProfileDataLocal = responses[0];
          // console.log('userProfileDataLocal:', userProfileDataLocal)
          // console.log('responses[1]:', responses[1])

          const iData = [];
          const lastData = [];

          let nameFieldTextDefault = 'text';
          let nameFieldText = nameFieldTextDefault;
          let lentCode = userProfileDataLocal?.UserProfile?.preferred_language;
          if (lentCode === 'uk') {
            lentCode = 'ua';
          }
          if (lentCode === 'en') {
            lentCode = '';
          }

          if (lentCode) {
            let newNameFieldText = `${nameFieldTextDefault}_${lentCode}`;

            if (responses[1]?.data[0] && typeof responses[1].data[0][newNameFieldText] !== 'undefined') {
              nameFieldText = newNameFieldText;
            }
          }

          responses[1]?.data.forEach((i, index) => {
            iData.push(<p key={`${i.date_create}-${index}`} >
              {i.date_create} <br/> {i[nameFieldText]} </p>)
          })
          if (iData.length > 0) {
            lastData.push(iData.shift());
          }
          setAttentionListContent(iData);
          setAttentionTopContent(lastData);
          setLoading(false);

        }
      })
      .catch((error) => {
        console.log('error:', error);
        const {errorMsg} = showErrors(error);
        message.error(errorMsg);
      })
      .finally(() => {
        if (!cancelled) {
          setLoading(false);
        }
      });

    return () => cancelled = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if (attentionTopContent && attentionTopContent.length === 0) {
    return null;
  }

  return (
    <Modal
      className='attention-modal'
      title={'Dear partners!'}
      visible={visibleModal && !loading}
      okText='OK'
      onOk={() => {
        const currentTime = new Date().getTime();
        localStorage.setItem('lastShowAttentionTime', `${currentTime}`);
        onShow();
        setVisibleModal(false);
      }}
      onCancel={() => {
        onShow();
        setVisibleModal(false)
      }}
      cancelButtonProps={{
        style: {display: 'none'},
      }}
      // width={400}
    >
      <div style={{padding: 5}}>

        {attentionTopContent}

        {attentionListContent?.length > 0 ?
          (
            <>
              <span id="more" style={{display: readMore ? 'inline' : 'none'}}>
                <p></p>
                {attentionListContent}
              </span>
              <Button
                type='primary'
                className='read-more'
                onClick={() => setReadMore((prevState) => !prevState )}>
                {!readMore ? "Read more" : "Collapse text"}
              </Button>
            </>
          ) : null}

        <p className='ant-modal-bottom-title'>Sincerely, yours Lion Trans!</p>
      </div>
    </Modal>
  )
}

const mapStateToProps = ({ userProfile }) => {
  return { userProfile }
};

export default connect(mapStateToProps)(AttentionModal);


const needShowAttentionModal = (flagCalculator = false) => {

  const lastShowAttentionTime_ = +localStorage.getItem('lastShowAttentionTime');
  const currentTime = new Date().getTime();

  if (!lastShowAttentionTime_ && !flagCalculator) {
    return true;
  }

  if (flagCalculator) {
    return (Math.abs(lastShowAttentionTime_ - currentTime) / 1000 >= 60 * 60 * 36);
  }

  return false;
}

export { needShowAttentionModal }