import React, { useState, useEffect, useRef } from 'react';
import {
  Button, Spin
} from 'antd';

import './download-link.css';
import UploadsService from "services/UploadsService";


const DownloadLink = (props) => {
  const {key_, href_, children, style = {}, showDownloadingText = null} = props;
  const [isDownloading, setIsDownloading] = useState(false);
  const isMountedRef = useRef(true);

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const downloadHandler = () => {

    if (!href_) {
      return null;
    }

    if (isDownloading) {
      return null;
    }

    setIsDownloading(true);

    return UploadsService._download(href_)
      .then((res) => {

        if (!isMountedRef.current) {
          return;
        }

        setIsDownloading(false);

        let filename = key_;
        const contentDisposition = res.responseHeaders['content-disposition'];

        if (contentDisposition && contentDisposition.indexOf('inline') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(contentDisposition);
          if (matches !== null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }

        const url = window.URL.createObjectURL(new Blob([res.data]));
        const a = document.createElement('a');
        a.setAttribute('rel', 'nofollow');
        a.setAttribute('target', '_blank');
        a.setAttribute('href', url);
        a.setAttribute('download', filename);
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch(() => {

        if (!isMountedRef.current) {
          return;
        }

        setIsDownloading(false);
      });
  }

  if (!isMountedRef.current) {
    return null;
  }

  return (
    <Button type="link"
            size={'small'}
            className="d-inline-flex"
            style={{marginRight: 5, padding: 0, ...style}}
            key={key_}
            onClick={downloadHandler}
    >
      {isDownloading
        ? (
            <>
              <Spin size="small" style={{marginRight: 10}}/>{showDownloadingText && (<>{showDownloadingText}</>)}
            </>
          )
        : (
            children
         )
      }
    </Button>
  );
}

export default DownloadLink;