import {
    SHOW_MESSAGE_USER_PROFILE,
    HIDE_MESSAGE_USER_PROFILE,
    SET_EMPTY_USER_PROFILE,
    SET_TRY_GET_USER_PROFILE,
    REFRESH_DATA_USER_PROFILE,
    GET_USER_PROFILE,
    UPDATE_USER_PROFILE
} from '../../constants/UserProfile';


export const showMessageUserProfile = (title, message) => {
    return {
        type: SHOW_MESSAGE_USER_PROFILE,
        payload: {title, message}
    };
};

export const hideMessageUserProfile = () => {
    return {
        type: HIDE_MESSAGE_USER_PROFILE
    };
};


export const setEmptyUserProfile = () => {
    return {
        type: SET_EMPTY_USER_PROFILE
    }
};

export const setTryGetUserProfile = (token) => {
    return {
        type: SET_TRY_GET_USER_PROFILE,
        payload: token
    }
};
export const refreshDataUserProfile = (token) => {
    return {
        type: REFRESH_DATA_USER_PROFILE,
        payload: token
    }
};

export const getUserProfile = (user, userProfile, token) => {
    return {
        type: GET_USER_PROFILE,
        payload: {
            token: token,
            user: {
                email: user.email
            },
            userProfile: {
                firstName: userProfile.first_name,
                lastName: userProfile.last_name,
                birthday: userProfile.birthday,
                companyName: userProfile.company_name,
                phone: userProfile.phone,
                typeUser: userProfile.type_user,
                avatar: userProfile.avatar,
                currencySymbol: userProfile.currency_symbol,
                preferredLanguage: userProfile.preferred_language
            }
        }
    }
};

export const updateUserProfile = (userProfile) => {
  return {
    type: UPDATE_USER_PROFILE,
    payload: {
      userProfile: {
        firstName: userProfile.first_name,
        lastName: userProfile.last_name,
        companyName: userProfile.company_name,
        phone: userProfile.phone,
        typeUser: userProfile.type_user,
        avatar: userProfile.avatar
      }
    }
  }
};
