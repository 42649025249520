import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import SideNav from 'components/layout-components/SideNav';
import TopNav from 'components/layout-components/TopNav';
import Loading from 'components/shared-components/Loading';
import MobileNav from 'components/layout-components/MobileNav'
import HeaderNav from 'components/layout-components/HeaderNav';
import PageHeader from 'components/layout-components/PageHeader';
import Footer from 'components/layout-components/Footer';
import AppViews from 'views/app-views';
import {
  Layout,
  Grid,
} from "antd";

import navigationConfig from "configs/NavigationConfig";
import { 
  SIDE_NAV_WIDTH, 
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  DIR_RTL,
  DIR_LTR
} from 'constants/ThemeConstant';
import utils from 'utils';
import { useThemeSwitcher } from "react-css-theme-switcher";
import AttentionModal, {needShowAttentionModal} from "../../components/shared-custom-components/AttentionModal";
import QuestionnaireModal, {needShowQuestionnaireModal} from "../../components/shared-custom-components/QuestionnaireModal";
import BirthdayCard, {needShowBirthdayCard} from "../../components/shared-custom-components/BirthdayCard";

const { Content } = Layout;
const { useBreakpoint } = Grid;

export const AppLayout = ({ navCollapsed, navType, location, direction }) => {
  const currentRouteInfo = utils.getRouteInfo(navigationConfig, location.pathname)
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes('lg')
  const isNavSide = navType === NAV_TYPE_SIDE
  const isNavTop = navType === NAV_TYPE_TOP
  const getLayoutGutter = () => {
    if(isNavTop || isMobile) {
      return 0
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH
  }
  const [isShowedAttentionModal, setIsShowedAttentionModal] = useState(false);
  const [isShowedQuestionnaireModal, setIsShowedQuestionnaireModal] = useState(false);
  const [isShowedBirthdayCard, setIsShowedBirthdayCard] = useState(false);

  const { status } = useThemeSwitcher();

  useEffect(() => {

    setIsShowedBirthdayCard(false);
    setIsShowedQuestionnaireModal(false);

    // ---eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRouteInfo]);


  if (status === 'loading') {
    return <Loading cover="page" />;
  }

  const getLayoutDirectionGutter = () => {
    if(direction === DIR_LTR) {
      return {paddingLeft: getLayoutGutter()}
    }  
    if(direction === DIR_RTL) {
      return {paddingRight: getLayoutGutter()}
    }
    return {paddingLeft: getLayoutGutter()}
  }


  let attention = null;
  let questionnaire = null;
  let birthdayCard = null;

  if (currentRouteInfo) {

    const needShow = currentRouteInfo.key === 'calculator'
      ? needShowAttentionModal(true)
      : needShowAttentionModal();

    if (needShow && (!isShowedAttentionModal || currentRouteInfo.key === 'calculator')) {
      attention = <AttentionModal onShow={() => setIsShowedAttentionModal(true)}/>;
    }

    const needShowQuestionnaire = needShowQuestionnaireModal();

    if (needShowQuestionnaire && !isShowedQuestionnaireModal) {
      questionnaire = <QuestionnaireModal onShow={() => setIsShowedQuestionnaireModal(true)}/>;
    }


    const isNeedShowBirthdayCard = needShowBirthdayCard();

    if (isNeedShowBirthdayCard && !isShowedBirthdayCard) {
      birthdayCard = <BirthdayCard onShow={() => setIsShowedBirthdayCard(true)}/>;
    }

  }

  const contentModal = birthdayCard
    ? birthdayCard
      : attention
        ? attention
        : questionnaire
          ? questionnaire
          : null;

  return (
    <Layout>
      <HeaderNav isMobile={isMobile}/>
      {(isNavTop && !isMobile) ? <TopNav routeInfo={currentRouteInfo}/> : null}
      <Layout className="app-container">
        {(isNavSide && !isMobile) ? <SideNav routeInfo={currentRouteInfo}/> : null }
        <Layout className="app-layout" style={getLayoutDirectionGutter()}>
          <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
            <PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title} />
            <Content>
              <AppViews />
            </Content>
          </div>
          {isMobile && <Footer routeInfo={currentRouteInfo} />}
        </Layout>
      </Layout>
      {isMobile && <MobileNav />}
      {contentModal}
    </Layout>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } =  theme;
  return { navCollapsed, navType, locale }
};

export default connect(mapStateToProps)(React.memo(AppLayout));