import React, {useEffect} from 'react';
import {Badge, message} from 'antd';
import { 
  BellOutlined,
} from '@ant-design/icons';
import HelpDeskService from "../../services/HelpDeskService";
import {showErrors} from "../../services/utils";
import {connect} from "react-redux";
import {haveUnreadMessage} from "../../redux/actions/HelpDesk";
import {Link} from "react-router-dom";


export const NavNotification = (props) => {
  const {haveUnreadMessage, countUnreadMessages, isMobile} = props;

  useEffect(() => {
    let cancelled = false;

    let interval = props.allowedHelpDesk && setTimeout(function getHelpDeskNotificationList() {
      HelpDeskService.getTotalUnreadMessagesCount()
        .then((res) => {
          if (!cancelled) {
            haveUnreadMessage(res.data);
          }
        })
        .catch((error) => {
          haveUnreadMessage(0);
          const {errorMsg} = showErrors(error);
          message.error(errorMsg);
        });
      interval = setTimeout(getHelpDeskNotificationList, 1 * 60 * 1000);
    }, 1000);

    return () => {
      clearInterval(interval);
      cancelled = true;
    }
  }, [props.needUpdateUnreadMessages, props.allowedHelpDesk, haveUnreadMessage, countUnreadMessages]);


  return (
    <Link
      to={{
        pathname: `/app/help-desk/tickets`,
      }}
      style={{
        padding: '0 1rem',
        visibility: isMobile ? 'hidden' : 'visibility',
        display: 'flex',
        alignItems: 'center'
      }} >
      <Badge count={countUnreadMessages}>
        <BellOutlined className="nav-icon mx-auto" type="bell" />
      </Badge>
    </Link>
  )
}


const mapStateToProps = ({ helpDesk }) => {
  const { needUpdateUnreadMessages, allowedHelpDesk, countUnreadMessages } =  helpDesk;
  return { needUpdateUnreadMessages, allowedHelpDesk, countUnreadMessages }
};

const actionCreators = {
  haveUnreadMessage
};

export default connect(mapStateToProps, actionCreators)(NavNotification);
