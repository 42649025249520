import React from 'react';
import { connect } from "react-redux";
import {SearchOutlined} from "@ant-design/icons";
import {Input} from "antd";

const SearchInput = props => {
  const { onChange, searchQuery, ref_ } = props;

  return (
    <Input ref={ref_} placeholder='Search' prefix={<SearchOutlined />} onChange={onChange} value={searchQuery ?? ''} allowClear/>
  );
}

const mapStateToProps = ({ headerSearch }) => {
  const { onChange, searchQuery } =  headerSearch;
  return { onChange, searchQuery }
};
export default connect(mapStateToProps)(SearchInput);
