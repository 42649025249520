import fetch from 'auth/FetchInterceptor'
import generateBoundary from "./utils";

const UserProfileService = {
	_url: '/rest/v1',

  async getNavPermissions() {

    return fetch({
      url: `${this._url}/get-nav-permissions`,
      method: 'get',

    }).then((resp) => {
      return resp.data;
    });
  },

  async getCustomerAdditionalSettings() {

    return fetch({
      url: `${this._url}/get-customer-additional-settings`,
      method: 'get',

    }).then((resp) => {
      return resp.data;
    });
  },

  async getRulesAndPolicies() {

    return fetch({
      url: `${this._url}/get-rules-and-policies`,
      method: 'get',

    }).then((resp) => {
      return resp.data;
    });
  },

  async acceptRulesAndPolicies(data) {

    return fetch({
      url: `${this._url}/accept-rules-and-policies`,
      method: 'post',
      data: data
    }).then((resp) => {
      return resp.data;
    });
  },


  async getProfile() {

		return fetch({
			url: `${this._url}/get-profile`,
			method: 'get',

		}).then((resp) => {
			return resp.data;
		});
	},

  async optSaveProfile() {

    return fetch({
      url: `${this._url}/save-profile`,
      method: 'options',

    }).then((resp) => {
      return resp.data;
    });
  },

  async saveProfile(data) {

    return fetch({
      url: `${this._url}/save-profile`,
      method: 'patch',
      headers: {
        'Content-Type': `multipart/form-data; boundary=${generateBoundary()}`,
      },
      data,
    }).then((resp) => {
      return resp.data;
    });
  },

  async deleteOwnAvatar() {

    return fetch({
      url: `${this._url}/delete-own-avatar`,
      method: 'patch',
    }).then((resp) => {
      return resp.data;
    });
  },

  async changePassword(data) {

    return fetch({
      url: `${this._url}/change-password`,
      method: 'post',
      data: data
    }).then((resp) => {
      return resp.data;
    });
  },

}
export default UserProfileService;


