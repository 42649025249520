import axios from 'axios'
import {API_BASE_URL} from 'configs/AppConfig'
import history from '../history'
import {AUTH_TOKEN} from 'redux/constants/Auth'
import {notification} from 'antd';

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000
})

// Config
const ENTRY_ROUTE = '/auth/login'
const TOKEN_PAYLOAD_KEY = 'authorization'
const PUBLIC_REQUEST_KEY = 'public-request'

// API Request interceptor
service.interceptors.request.use(config => {
	const jwtToken = localStorage.getItem(AUTH_TOKEN)
	
  if (jwtToken) {
    config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`;
  }

  if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
		history.push(ENTRY_ROUTE)
		window.location.reload();
  }

  return config
}, error => {
	// Do something with request error here
	notification.error({
		message: 'Error config!'
	})
  Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use( (response) => {
	//console.log(response);
	return {data: response.data, responseHeaders: response.headers}
}, async (error) => {

	let notificationParam = {
		message: `Error`
	}
	if (error?.response === undefined) {
		notificationParam.description = error.toString();
		notification.error(notificationParam)
		return Promise.reject(error);
	}

	if (error?.response?.status === 403) {
		notificationParam.message = error.response.statusText;

    console.log( 'error.response',  error.response)
    if (error.response.data instanceof Blob) {
      try {
        const text = await error.response.data.text();
        const json = JSON.parse(text);
        notificationParam.description = json.error || text;
      } catch (blobError) {
        notificationParam.description = 'Error reading error message.';
      }
    }

    if (typeof error.response.data?.error === 'string') {
			notificationParam.description = error.response.data?.error;
		}
		notification.error(notificationParam)

		return {data: {}};
	}

	if (error?.response?.status === 400) {
		notificationParam.message = error.response.statusText;

		if (typeof error.response.data?.error === 'string') {
			notificationParam.description = error.response.data?.error;
		}
		if (Array.isArray(error.response.data?.error)) {

			notificationParam.description = <div>{error.response.data.error.map((v, i) => {
				if (typeof v === 'string') {
					return <p key={i}>{v}</p>;
				}
				return null;
			})}</div>;
		}

    if (Object.prototype.toString.call(error.response.data?.error) === '[object Object]') {
      let {error:errorObj} = error.response.data;
      let errorList = [];
      for (let key in errorObj) {
        if (errorObj.hasOwnProperty(key)) {
          errorList.push(<p key={key}>{key}: {errorObj[key]}</p>)
        }
      }
      notificationParam.description = <div>{errorList}</div>;
    }

	}

	// Remove token and redirect
	if (error?.response?.status === 401) {
		notificationParam.message = error.response.statusText;
		notificationParam.description = 'Please login again';

		localStorage.removeItem(AUTH_TOKEN)
		history.push(ENTRY_ROUTE)
		window.location.reload();
	}

	if (error?.response?.status === 404) {
		notificationParam.message = 'Not Found'
	}

	if (error?.response?.status === 500) {
		notificationParam.message = 'Internal Server Error'
	}
	
	if (error?.response?.status === 508) {
		notificationParam.message = 'Time Out'
	}

	notificationParam.message = error?.response?.statusText;

	notification.error(notificationParam)

	return Promise.reject(error);
});

export default service