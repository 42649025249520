import React, {useEffect, useState} from 'react';
import {message, Modal} from 'antd';
import './birthday-card.css';
import CommonService from "../../../services/CommonService";
import isEmpty from "lodash/isEmpty";
import {showErrors} from "../../../services/utils";
import UserProfileService from "../../../services/UserProfileService";
import moment from "moment";

// const nameItemStorage = 'lastYearBirthdayGreeting';

const BirthdayCard = (props) => {

  const {onShow} = props;

  const [visibleModal, setVisibleModal] = useState(false);
  const [greetingData, setGreetingData] = useState(null);

  useEffect(() => {
    let cancelled = false;

    setVisibleModal(true);

    let requests = [
      UserProfileService.getProfile(),
      CommonService.getHappyBirthdayGreeting()
    ];
    Promise.all(requests)
      .then((responses) => {
        if (!cancelled) {

          const userProfile = responses[0]?.UserProfile;
          if (isEmpty(userProfile)) {
            onShow();
            return;
          }

          const birthdayString = userProfile.birthday;
          if (!birthdayString) {
            onShow();
            return;
          }

          const currentDate = moment();
          const birthdayParts = birthdayString.split('-');

          const birthday = moment({
            year: currentDate.year(),
            month: parseInt(birthdayParts[1]) - 1,
            day: parseInt(birthdayParts[2]),
          });

          const startOfGreetingsRange = birthday.clone();
          const endOfGreetingsRange = birthday.clone().add(1, 'months');

          const isWithinGreetingsRange = moment().isBetween(startOfGreetingsRange, endOfGreetingsRange, null, '[]');

          // const lastYearBirthdayGreeting = localStorage.getItem(nameItemStorage) || 0;

          let lastYearBirthdayGreeting = '0';
          if (responses[1].data?.last_birthday_greeting) {
            lastYearBirthdayGreeting = moment(responses[1].data.last_birthday_greeting).year().toString();
          }

          if (!isWithinGreetingsRange || lastYearBirthdayGreeting === moment().year().toString()) {
            onShow();
            return;
          }

          setGreetingData(responses[1].data);
          // localStorage.setItem(nameItemStorage, moment().year().toString());
        }
      })
      .catch((error) => {
        console.log('error:', error);
        const {errorMsg} = showErrors(error);
        message.error(errorMsg);
      })

    return () => {
      cancelled = true;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onConfirm = () => {
    doneGreeting();
    onShow();
  };

  const onCancel = () => {
    doneGreeting();
    setVisibleModal(false);
    onShow();
  };

  const doneGreeting = () => {

    CommonService.doneGreeting()
      .then((_response) => {

      })
      .catch((error) => {
        const {errorMsg} = showErrors(error);
        console.log('errorMsg:', errorMsg);
      })

  }


  const renderContent = () => {

    return (
      <div className="birthdayCard">
        <div
          className="cardFront"
          style={{
            backgroundImage: 'url(/img/birthday-card.png)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',

          }}
        >

        </div>
        <div className="cardInside">
          <h3 className="back">HAPPY BIRTHDAY!</h3>
          {/*<p>Dear Friend,</p>*/}
          {/*<p>*/}
          {/*  Happy birthday!! I hope your day is filled with lots of love and*/}
          {/*  laughter! May all of your birthday wishes come true.*/}
          {/*</p>*/}
          {/*<p className="name">Pawan</p>*/}
          <div
            className={'greeting-content'}
            dangerouslySetInnerHTML={{ __html: greetingData.text }}
          />
        </div>
      </div>
    );

  }


  if (isEmpty(greetingData)) {
    return null;
  }

  return (
    <Modal
      className='birthday-card-modal'
      visible={visibleModal}
      onOk={onConfirm}
      onCancel={onCancel}
      footer={false}
    >
      {renderContent()}
    </Modal>
  )
}

export default BirthdayCard;

const needShowBirthdayCard = () => {

  return true;

}

export { needShowBirthdayCard }