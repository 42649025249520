import React, {useEffect, useState} from 'react'
import {Row, Col, Card, message, Button, Spin} from "antd";
import './accept-rules.css';
import Checkbox from "antd/es/checkbox/Checkbox";
import UserProfileService from "../../../services/UserProfileService";
import {showErrors} from "../../../services/utils";
import DownloadLink from "../../shared-custom-components/DownloadLink";

const AcceptRules = (props) => {

  const [loading, setLoading] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [acceptedRules, setAcceptedRules] = useState(false);
  const [confirmedAcceptedRules, setConfirmedAcceptedRules] = useState(false);

  const [fileRules, setFileRules] = useState({});

  useEffect(() => {

    let cancelled = false;

    UserProfileService.getRulesAndPolicies()
      .then((res) => {

        if (cancelled) {
          return;
        }

        setFileRules(res);

        // console.log('res:',res)
        setLoading(false);
      })
      .catch((error) => {
        //console.log('error:', error.response);
        const {errorMsg} = showErrors(error);
        message.error(errorMsg);
      })
      .finally(() => {
      });

    return () => cancelled = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeAcceptedRules = (event) => {
    setAcceptedRules(event.target.checked);
  }

  const submit = () => {
    setConfirmLoading(true);

    UserProfileService.acceptRulesAndPolicies({
      'rules_are_accepted': acceptedRules,
    })
      .then((res) => {
        // console.log('acceptRulesAndPolicies:', res)

        // props.onConfirm(res?.rules_are_accepted)

        setConfirmedAcceptedRules(res?.rules_are_accepted);
      })
      .catch((error) => {
        setConfirmLoading(false);
        //console.log('error:', error.response);
        const {errorMsg} = showErrors(error);
        message.error(errorMsg);
      });
  }

  let content

  if (!confirmedAcceptedRules) {
    content = (<>
      <h3>Before starting work, please read</h3>
      <p className={'file-rules'}>

        <DownloadLink
          key = {`file-rules`}
          key_ = {`file-rules`}
          href_ = {fileRules.url_file}
          style={{
            fontSize: 16,
          }}
        >
          “Rules for working with Lion Trans company”
        </DownloadLink>

      </p>

      <Checkbox onChange={onChangeAcceptedRules}>I agree</Checkbox>

      <p style={{marginTop: 20,}}>
        By clicking “I agree”, you confirm that youare familiar with the
      </p>
      <p>
        “Rules for working with Lion Trans company” and undertake to comply with them.
      </p>

      <Button
        type="primary"
        loading={loading}
        disabled={!acceptedRules || confirmLoading}

        style={{
          marginTop: 15
        }}
        onClick={submit}
      >
        {confirmLoading ? <Spin /> : null} Continue
      </Button>
    </>);
  }

  if (confirmedAcceptedRules) {
    content = (
      <div
        className={'welcome'}
      >
        <p>Welcome to our Lion Trans family!</p>
        <p>We appreciate your decision to grow and improve with us every day.</p>
        <p>Why is it important?</p>
        <p>It is because our ultimate goal is to provide
          the highest level of service, which plays a crucial role
          in your company`s success.
        </p>
        <Button
          type="primary"
          style={{
            marginTop: 15
          }}
          onClick={() => props.onConfirm(confirmedAcceptedRules)}
        >
          Get started
        </Button>
      </div>
    );
  }

	return (
    <div
      style={{height: '100vh'}}
    >
      <div className={`h-100 bg-white`}>
        <Row justify="center" className="align-items-stretch h-100">
          <Col xs={24} sm={24} md={24} lg={14}>
            <div className="container d-flex flex-column justify-content-center h-100">
              <Row justify="center">
                <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                  <Card
                    className={'accept-rules-card text-center'}
                    loading={loading}
                  >
                    {content}
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </div>
	)
}

export default AcceptRules

